<template>
  <a-card>
    <a-page-header :title="'账套标签'" />
    <div class="flex-end">
      <base-button type="primary" title="创建" @onClickBtn="handleNew"></base-button>
    </div>
    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="dataList"
      :row-key="(record, index) => index"
      :pagination="false"
    >
      <template slot="action" slot-scope="text, record">
        <a-space>
          <a-button type="link" size="small" @click="handleEdit(record)">编辑</a-button>
          <a-popconfirm
            placement="right"
            ok-text="删除"
            cancel-text="取消"
            style="text-align: center; cursor: pointer"
            @confirm.stop="handleDelete(record)"
          >
            <template slot="title">
              <p>确认删除标签?</p>
            </template>
            <a-button type="link" size="small">删除</a-button>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table>
    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
    <a-modal
      :visible="visible"
      title="编辑账套"
      :width="720"
      :mask-closable="false"
      :confirmLoading="submitLoading"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <a-form-model ref="form" :model="form" v-bind="layout" :rules="rules">
        <a-form-model-item label="标签名称" prop="tagName">
          <a-input v-model="form.tagName" placeholder="请输入20字以下" />
        </a-form-model-item>
        <a-form-model-item label="账套ID" prop="adPlatformAccountId">
          <a-input v-model="form.adPlatformAccountId" type="textarea" placeholder="请用逗号分隔输入" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import accountHjkTagApi from '@/api/accountHjkTag';

export default {
  data() {
    return {
      isNew: true,
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
      submitLoading: false,
      form: {
        tagName: '',
        adPlatformAccountId: '',
      },
      rules: {
        tagName: [{ required: true, max: 20, message: '请输入名称且在20字以下', trigger: 'change' }],
        adPlatformAccountId: [{ required: true, message: '请输入账套ID', trigger: 'change' }],
      },
      visible: false,
      currentRow: {},
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      dataList: [],
      columns: [
        {
          align: 'left',
          title: '标签ID',
          dataIndex: 'id',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'left',
          title: '标签名称',
          dataIndex: 'tagName',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'left',
          title: '账套数',
          dataIndex: 'accountCount',

          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'left',
          title: '最后操作人',
          dataIndex: 'actor',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'left',
          title: '最后操作时间',
          dataIndex: 'atime',
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          scopedSlots: { customRender: 'action' },
        },
      ],
    };
  },
  computed: {
    params() {
      return {
        ...this.form,
        page: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };
    },
    currentTagId() {
      return this.currentRow.id;
    },
  },
  created() {
    this.getDataList();
  },
  methods: {
    handleVisible() {
      this.visible = !this.visible;
    },
    handleNew() {
      this.isNew = true;
      this.handleVisible();
    },
    async handleEdit(record) {
      this.currentRow = record;
      this.form.tagName = record.tagName;
      this.isNew = false;
      await this.getAccountIdListByTagId();
      this.handleVisible();
    },
    handleDelete(record) {
      accountHjkTagApi.deleteHjkTagByTagIdApi({ id: record.id }).then((res) => {
        if (res.code == 200) {
          this.$message.success('删除成功');
          this.getDataList();
        }
      });
    },
    handleCancel() {
      this.handleVisible();
      this.form = {
        tagName: '',
        adPlatformAccountId: '',
      };
      this.currentRow = {};
    },
    handleOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          const payload = {
            id: this.currentTagId || undefined,
            tagName: this.form.tagName,
          };
          accountHjkTagApi.addOrUpdateHjkTagApi(payload).then((res) => {
            if (res.code == 200) {
              this.getDataList('insert');
            }
          });
        }
      });
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    getDataList(type) {
      this.loading = true;
      accountHjkTagApi.getHjkTagListApi().then((res) => {
        this.loading = false;
        if (res.code == 200) {
          this.dataList = res.data.list || [];
          this.pagination.total = res.data.total || 0;

          this.setHjkTagRelation(type);
        } else {
          this.dataList = [];
          this.pagination.total = 0;
          console.error(`获取列表失败${res}`);
        }
      });
    },
    setHjkTagRelation(type) {
      if (type === 'insert') {
        accountHjkTagApi
          .setHjkTagRelationApi({
            tagId: this.isNew ? this.dataList[0].id : this.currentTagId,
            adPlatformAccountIdList: this.form.adPlatformAccountId.replace(/，/g, ',').split(','),
          })
          .then((res) => {
            if (res.code == 200) {
              let msg = `关联成功${res.data.successIdList.length}条`;

              if (res.data.failedIdList.length > 0) {
                msg += ` , 失败${res.data.failedIdList.length}条:${res.data.failedIdList.join(',')},请检查`;
                this.$message.warning(msg);
              } else {
                this.$message.success(msg);
                this.handleVisible();
                this.getDataList();
              }
            }
          })
          .finally(() => {
            this.submitLoading = false;
          });
      }
    },
    getAccountIdListByTagId() {
      return accountHjkTagApi.getAccountIdListByTagIdApi({ tagId: this.currentTagId }).then((res) => {
        if (res.code == 200) {
          this.form.adPlatformAccountId = res.data.join(',');
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
  padding-left: 0;
  padding-right: 0;
}
.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
}
</style>
